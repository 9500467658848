import React, { useEffect, useState } from 'react';
import './NewsUpdate.css';

const NewsUpdate = () => {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    // Simulate fetching data from an API
    const fetchData = () => {
      const data = [
        {
          id: 1,
          content: "EXAM FORM OPEN FOR TEE DECEMBER 2024 👉 https://exam.ignou.ac.in/ Last date to Apply Online* *09.09.2024 to 15-Oct-2024 06:00 PM without Late fee",
          createdAt: new Date()
        },
        {
          id: 2,
          content: "दिल्ली RC 3 ने जारी की ASSIGNMENT SUBMIT करने की GUIDLINES 👉 http://rcdelhi3.ignou.ac.in/studentcorner/14",
          createdAt: new Date()
        },
        {
          id: 3,
          content: "The university has uploaded the assignment for Term End Examination December 2024. The students can now download the IGNOU Assignment for their courses to submit it before the due date. Link to Download 👉 https://webservices.ignou.ac.in/assignments/",
          createdAt: new Date()
        }
      ];
      setNewsData(data);
    };

    fetchData();
  }, []);

  const extractAndRenderLinks = (content) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.split(urlRegex).map((part, index) => 
      urlRegex.test(part) ? (
        <a href={part} target="_blank" rel="noopener noreferrer" key={index} className="news-link">{part}</a>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };

  return (
    <div className="flesh-container">
      <div className="flesh">
        <div className="marquee">
          {newsData.map(item => (
            <div className="marquee-item" key={item.id}>
              {extractAndRenderLinks(item.content)}
            </div>
          ))}
        </div>
        <div className="marquee">
          {newsData.map(item => (
            <div className="marquee-item" key={item.id + newsData.length}>
              {extractAndRenderLinks(item.content)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsUpdate;
