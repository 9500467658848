
import React from 'react';
import ImageSlider from '../Slider/imageSlider';
import './Home.css';
import YouTubeVideo from '../YouTube/YouTubeVideo';

import NewsUpdate from '../NewsUpdate/Index';

const Home = ({ productData, image }) => {
  return (
    <div className='home-page-layout'>
      <NewsUpdate />
      <ImageSlider image={image} />
      <br></br>
      <center><h1>Our YouTube Video</h1></center>
      <div className='Youtube-cannel'>
        <YouTubeVideo />
      </div>
      <center><h1>About Us</h1></center>
      <div className="about-us">
        <div className='about-p-button'>
          <p>
            At Dalal Technologies, operating under the banner of WaveArt, we are committed to revolutionizing your academic experience. Specializing in providing solutions for old question papers, assignments, and university projects, we aim to simplify your educational journey.
          </p>
          <button className="learn-more-button" onClick={() => window.location.href = "/AboutUs"}>
            Learn More
          </button>
        </div>
        <img className='about-image' src="Images/Exams.png" alt="PDF 1" />
      </div>
    </div>
  );
}

export default Home;
