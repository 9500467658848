import React, { useState, useEffect } from 'react';
import './imageSlider.css';

const ImageSlider = ({ image }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(prevSlide => (prevSlide + 1) % image.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [image.length]);

  return (
    <div className="imageslider-home">
      {image.map((image, index) => (
        <img
          className='slider-img'
          key={index}
          src={`https://waveart.in/uploads/${image.ImgUrl}`}
          alt={image.Title}
          style={{ 
            display: index === currentSlide ? 'block' : 'none',
            width: '100%', // Make the image responsive
            height: 'auto' // Ensure the height adjusts accordingly
          }}
        />
      ))}
    </div>
  );
};

export default ImageSlider;
