import React from 'react';
import './Modal.css';

const Modal = ({ isVisible, onClose }) => {
  // Return null if the modal should not be visible
  if (!isVisible) return null;

  return (
    <div className={`modal-overlay ${isVisible ? 'show' : ''}`} onClick={onClose}>
      <div 
        className="modal-content"
        onClick={e => e.stopPropagation()} // Prevent click events from closing the modal
      >
        <button className="close-button" onClick={onClose}>X</button>
        <img src='/Images/handwritten.jpg' alt="Welcome" className="modal-image" />
      </div>
    </div>
  );
};

export default Modal;
