import React, { useState } from 'react';
import './Contact.css';
import axios from 'axios';

const ContactUs = () => {
  const [inputValue, setInputValue] = useState({
    username: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleValue = (e) => {
    const { name, value } = e.target;
    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      [name]: value,
    }));
  };

  const handleLoginForm = async (e) => {
    e.preventDefault();

    const { username, email, mobile, subject, message } = inputValue;
    // Form validation
    if (!username || !email || !mobile || !subject || !message) {
      setResponseMessage("All fields are required.");
      return;
    }

    // Email validation
    if (!/\S+@\S+\.\S+/.test(email)) {
      setResponseMessage("Invalid email address.");
      return;
    }

    try {
      // Send the email using an API endpoint

      await axios.post('https://waveart.in/Contact', inputValue);

      setIsFormSubmitted(true);
      setResponseMessage('Wave sent successfully!');
      setInputValue({
        username: "",
        email: "",
        mobile: "",
        subject: "",
        message: "",
      });

      setTimeout(() => {
        setIsFormSubmitted(false);
        setResponseMessage("");
      }, 5000);
    } catch (error) {
      console.error('Error sending wave:', error);
      setIsFormSubmitted(false);
      setResponseMessage('An error occurred while waveing.');
    }
  };

  return (
    <div className="contact-container">
      <form action="#" className="contact-us" onSubmit={handleLoginForm}>
        <h2 className="contact-title" > Contact Us</h2>
        <input className='contact-input-filed'
          type="text"
          placeholder="Your Name"
          name="username"
          value={inputValue.username}
          onChange={handleValue}
        />
        <input
          className="contact-input-filed"
          type="text"
          placeholder="Your Email"
          name="email"
          value={inputValue.email}
          onChange={handleValue}
        />

        <input
          className="contact-input-filed"
          type="number"
          placeholder="Mobile Number"
          name="mobile"
          value={inputValue.mobile}
          onChange={handleValue}
        />
        <input
          type="text"
          className="contact-input-filed"
          placeholder="Subject"
          name="subject"
          value={inputValue.subject}
          onChange={handleValue}
        />
        <input
          type="text"
          className="input-field-message"
          placeholder="Your Message"
          name="message"
          value={inputValue.message}
          onChange={handleValue}
        /><br></br>
          <button className="button" disabled={isFormSubmitted}>
            {isFormSubmitted ? 'Sending...' : 'Send'}
          </button>
          {responseMessage && <p>{responseMessage}</p>}
      </form>

      <div className="contact-image-container">
        <img src="Images/contact.png" className="contact-image" alt="" />
        <h2>Address </h2>
        <p>12/196,Geeta Colony,Delhi Pin-110031
          <br></br>
          Email-Support@dalaltechnologies.com <br></br>Mb-9654400726
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
