// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-upload {
  margin-left: 86px; /* Space for admin navbar */
  padding: 20px;
}

.blog-upload h2 {
  color: #333;
}

.blog-input {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
}

.file-input {
  margin-bottom: 10px;
}

.button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
}

.blog-list {
  margin-top: 20px;
}

.blog-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.blog-item {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
}

.srcimg1 {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
}

.blog-content h3 {
  margin-bottom: 10px;
}

.blog-content button {
  margin-top: 10px;
}

/* Media query for screens up to 768px */
@media screen and (max-width: 768px) {
  .blog-upload {
    margin-left: 0;
    padding: 10px;
  }

  .button {
    width: 100%;
    margin-top: 10px;
    margin-right: 0;
  }

  .blog-items {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .blog-item {
    padding: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Admin/BlogsUpload/BlogUpload.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB,EAAE,2BAA2B;EAC9C,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,2DAA2D;EAC3D,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA,wCAAwC;AACxC;EACE;IACE,cAAc;IACd,aAAa;EACf;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,2DAA2D;EAC7D;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".blog-upload {\n  margin-left: 86px; /* Space for admin navbar */\n  padding: 20px;\n}\n\n.blog-upload h2 {\n  color: #333;\n}\n\n.blog-input {\n  width: 100%;\n  margin-bottom: 10px;\n  padding: 8px;\n}\n\n.file-input {\n  margin-bottom: 10px;\n}\n\n.button {\n  background-color: #007bff;\n  color: #fff;\n  border: none;\n  padding: 10px 20px;\n  cursor: pointer;\n  margin-right: 10px;\n}\n\n.blog-list {\n  margin-top: 20px;\n}\n\n.blog-items {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  grid-gap: 20px;\n}\n\n.blog-item {\n  border: 1px solid #ccc;\n  padding: 20px;\n  border-radius: 5px;\n}\n\n.srcimg1 {\n  width: 100%;\n  border-radius: 5px;\n  margin-bottom: 10px;\n}\n\n.blog-content h3 {\n  margin-bottom: 10px;\n}\n\n.blog-content button {\n  margin-top: 10px;\n}\n\n/* Media query for screens up to 768px */\n@media screen and (max-width: 768px) {\n  .blog-upload {\n    margin-left: 0;\n    padding: 10px;\n  }\n\n  .button {\n    width: 100%;\n    margin-top: 10px;\n    margin-right: 0;\n  }\n\n  .blog-items {\n    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));\n  }\n\n  .blog-item {\n    padding: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
