import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CategoryForm.css';
import AdminNavbar from '../adminNavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const CategoryForm = () => {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [editing, setEditing] = useState(false);
  const [editingId, setEditingId] = useState('');
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [showConfirmEditDialog, setShowConfirmEditDialog] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://waveart.in/categories');
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (editing) {
        const response = await axios.put(`https://waveart.in/categories/${editingId}`, { category });
        setEditing(false);
        setEditingId('');
      } else {
        const response = await axios.post('https://waveart.in/categories', { category });
      }
      setCategory('');
      fetchCategories(); // Fetch the updated categories
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteClick = (id) => {
    setCategoryToDelete(id);
    setShowConfirmDeleteDialog(true);
  };

  const handleEditClick = (id) => {
    setEditingId(id);
    setShowConfirmEditDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (!categoryToDelete) return;

    try {
      await axios.delete(`https://waveart.in/categories/${categoryToDelete}`);
      fetchCategories(); // Fetch the updated categories
    } catch (error) {
      console.log(error);
    }
    
    setShowConfirmDeleteDialog(false);
    setCategoryToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowConfirmDeleteDialog(false);
    setCategoryToDelete(null);
  };

  const handleConfirmEdit = async () => {
    if (editingId) {
      try {
        const response = await axios.get(`https://waveart.in/categories/${editingId}`);
        setCategory(response.data.category);
        setEditing(true);
        setEditingId(editingId);
      } catch (error) {
        console.log(error);
      }
    }
    setShowConfirmEditDialog(false);
  };

  const handleCancelEdit = () => {
    setShowConfirmEditDialog(false);
    setEditingId('');
  };

  return (
    <div>
      <AdminNavbar />
      <div className="container11">
        <h2 className='heading'> Add New Category</h2>
        <form className='categoryFrom' onSubmit={handleSubmit}>
          <div className="group">
            <input
              className='inp'
              type="text"
              value={category}
              onChange={(event) => setCategory(event.target.value)}
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label className='labl'>Category</label>
            <button className='button' type="submit">{editing ? 'Update' : 'Submit'}</button>
          </div>
        </form>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category._id}>
                <td>{category._id}</td>
                <td>{category.category}</td>
                <td>
                  <button className="category-button" onClick={() => handleEditClick(category._id)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button className="category-button" onClick={() => handleDeleteClick(category._id)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showConfirmDeleteDialog && (
        <div className="confirm-dialog-overlay">
          <div className="confirm-dialog">
            <p>Do you want to delete this category?</p>
            <button className="confirm-button" onClick={handleConfirmDelete}>OK</button>
            <button className="cancel-button" onClick={handleCancelDelete}>Cancel</button>
          </div>
        </div>
      )}
      {showConfirmEditDialog && (
        <div className="confirm-dialog-overlay">
          <div className="confirm-dialog">
            <p>Do you want to edit this category?</p>
            <button className="confirm-button" onClick={handleConfirmEdit}>OK</button>
            <button className="cancel-button" onClick={handleCancelEdit}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryForm;
