import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";

const Navbar = ({ countCartItems }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsScrolled(scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${isScrolled ? "fixed" : ""}`}>
      <NavLink exact to="/">
        <img className="nav-logo" src="/Images/wave_logo.png" alt="image" />
      </NavLink>
      <ul className="nav-menu">
        <li className="navbar-links">
          <NavLink exact to="/" activeClassName="activeclassname" className="nav-links">
            Home
          </NavLink>
          <NavLink exact to="/Ignou-Solved-Assignment" activeClassName="activeclassname" className="nav-links">
            Shop
          </NavLink>
          <NavLink exact to="/Blogs" activeClassName="activeclassname" className="nav-links">
            Blog
          </NavLink>
          <NavLink exact to="/contact" activeClassName="activeclassname" className="nav-links">
            Contact Us
          </NavLink>
          <NavLink to="/Cart" className="nav-cart-link">
            <p className="count-cart-item">{countCartItems ? countCartItems : ""}</p>
            <img className="nav-cart-image" src="./Images/ShoppingCart.png" alt="cart" />
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
