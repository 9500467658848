// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for the upload management container */
.upload-management-container {
  display: flex;
  margin-left: 60px; /* Adjust for the fixed navbar */
  flex-wrap: wrap;
  padding: 20px;
  box-sizing: border-box; /* Ensures padding is included in width calculations */
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .upload-management-container {
    margin-left: 0; /* Remove margin on smaller screens */
    flex-direction: column; /* Stack items vertically */
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .update-list {
    width: 100%; /* Full width for smaller screens */
    margin: 0; /* Remove margin for better alignment */
  }
}

/* Optional: Add styles for children elements inside the container for better control */
.upload-management-container > * {
  margin-bottom: 20px; /* Add space between child elements */
}

.upload-management-container > :last-child {
  margin-bottom: 0; /* Remove bottom margin for the last child */
}
`, "",{"version":3,"sources":["webpack://./src/Admin/ProductManagement/UploadManagement/UploadManagement.css"],"names":[],"mappings":"AAAA,+CAA+C;AAC/C;EACE,aAAa;EACb,iBAAiB,EAAE,gCAAgC;EACnD,eAAe;EACf,aAAa;EACb,sBAAsB,EAAE,sDAAsD;AAChF;;AAEA,2BAA2B;AAC3B;EACE;IACE,cAAc,EAAE,qCAAqC;IACrD,sBAAsB,EAAE,2BAA2B;IACnD,aAAa,EAAE,uCAAuC;EACxD;;EAEA;IACE,WAAW,EAAE,mCAAmC;IAChD,SAAS,EAAE,uCAAuC;EACpD;AACF;;AAEA,uFAAuF;AACvF;EACE,mBAAmB,EAAE,qCAAqC;AAC5D;;AAEA;EACE,gBAAgB,EAAE,4CAA4C;AAChE","sourcesContent":["/* Styles for the upload management container */\n.upload-management-container {\n  display: flex;\n  margin-left: 60px; /* Adjust for the fixed navbar */\n  flex-wrap: wrap;\n  padding: 20px;\n  box-sizing: border-box; /* Ensures padding is included in width calculations */\n}\n\n/* Responsive adjustments */\n@media screen and (max-width: 768px) {\n  .upload-management-container {\n    margin-left: 0; /* Remove margin on smaller screens */\n    flex-direction: column; /* Stack items vertically */\n    padding: 10px; /* Reduce padding for smaller screens */\n  }\n\n  .update-list {\n    width: 100%; /* Full width for smaller screens */\n    margin: 0; /* Remove margin for better alignment */\n  }\n}\n\n/* Optional: Add styles for children elements inside the container for better control */\n.upload-management-container > * {\n  margin-bottom: 20px; /* Add space between child elements */\n}\n\n.upload-management-container > :last-child {\n  margin-bottom: 0; /* Remove bottom margin for the last child */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
