import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState({
    username: '',
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const API_URL = 'https://waveart.in/';

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [message]);

  const handleValue = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${API_URL}admin/signin`, // Use API_URL constant here
        inputValue
      );
      localStorage.setItem("token", response.data.token);
      if (response.status === 200) {
        setMessage('Login successful!');
        navigate('/AdminPanel');
      } else {
        setMessage("Login failed. Please try again.");
      }
    } catch (error) {
      setMessage('Invalid credentials. Please try again.');
      console.log(error);
    } finally {
      setIsLoading(false);
    }  
  };

  return (
    <div className="main-container">
      <div className="logo-container">
        <img src="/Images/wave_logo.png" alt="Logo" className="logo" />
        <p className="left-text">Welcome to our platform! Please login to continue.</p>
      </div>
      <div className="login-container">
        <h1 className="login-title">Login</h1>
        <form onSubmit={handleLoginForm}>
          <div className="form-group">
            <label htmlFor="userId">User ID:</label>
            <input
              type="text"
              id="userId"
              name="username"
              onChange={handleValue}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="Email">Email:</label>
            <input
              type="text"
              id="Email"
              name="email" // corrected name attribute
              onChange={handleValue}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="secondAuth">Password:</label>
            <div className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                id="secondAuth"
                name="password"
                value={inputValue.password}
                onChange={handleValue}
                required
              />
              <button
                type="button"
                className="password-toggle-btn"
                onClick={handleTogglePassword}
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
          </div>

          <button className="submit-btn" type="submit" disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Login'}
          </button>
        </form>
        {message && <div className="response">{message}</div>}
      </div>
    </div>
  );
};

export default Login;
