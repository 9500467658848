import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import AdminNavbar from '../adminNavbar';
import './AdminPanel.css'; // Import the CSS file for styling

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

// Helper function to get month name from index
const getMonthName = (monthIndex) => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  return months[monthIndex];
};

const AdminPanel = ({ productData }) => {
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [orderChartData, setOrderChartData] = useState({ successData: [], pendingData: [] });
  const [myorders, setMyOrders] = useState([]); // Initialize as an empty array

  useEffect(() => {
    const fetchOrdersAndProducts = async () => {
      try {
        const ordersResponse = await axios.get('https://waveart.in/pay/getOrder');
        setMyOrders(ordersResponse.data.orderData || []);
        // If you need to fetch product data as well, uncomment and update this part
        // const productsResponse = await axios.get('YOUR_PRODUCT_API_ENDPOINT');
        // setProductData(productsResponse.data || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchOrdersAndProducts();
  }, []);

  // Check if myorders exists before filtering
  const pendingOrders = myorders 
    ? myorders.filter(order => ['pending', 'Failure', 'N/A', 'Timeout'].includes(order.order_status)).length 
    : 0;
  
  const successOrders = myorders 
    ? myorders.filter(order => ['success', 'Success'].includes(order.order_status)).length 
    : 0;

  const totalOrders = myorders 
    ? myorders.filter(order => order.order_status).length 
    : 0;
    
  const totalRevenue = myorders 
    ? myorders
        .filter(order => ['success', 'Success'].includes(order.order_status))
        .reduce((acc, order) => {
            const orderAmount = parseFloat(order.amount);
            return !isNaN(orderAmount) ? acc + orderAmount : acc;
        }, 0)
    : 0;
  
  const totalProducts = productData ? productData.length : 0;

  // Function to generate chart data for given month and year
  const generateChartData = (ordersData, month, year) => {
    if (!ordersData) {
      return { labels: [], data: [] };
    }

    const revenueByDate = ordersData.reduce((acc, order) => {
      const orderDate = new Date(order.createdAt);
      if (orderDate.getFullYear() === year && ['success', 'Success'].includes(order.order_status)) {
        if (month === '') { // If no month selected, consider whole year
          const date = orderDate.toLocaleDateString('en-US', { month: 'long' }); // Get month name
          acc[date] = (acc[date] || 0) + parseFloat(order.amount);
        } else if (orderDate.getMonth() === month) { // If specific month selected, consider that month only
          const date = orderDate.toLocaleDateString(); // Get full date
          acc[date] = (acc[date] || 0) + parseFloat(order.amount);
        }
      }
      return acc;
    }, {});

    const labels = Object.keys(revenueByDate).sort((a, b) => new Date(a) - new Date(b));
    const data = labels.map(date => revenueByDate[date] || 0);
    return { labels, data };
  };

  // Function to generate custom month-wise order chart data
  const generateOrderChartData = (ordersData, month, year) => {
    if (!ordersData) {
      return { successData: [], pendingData: [] };
    }

    const successOrdersByDate = ordersData.filter(order => {
      const orderDate = new Date(order.createdAt);
      return orderDate.getMonth() === month && orderDate.getFullYear() === year && ['success', 'Success'].includes(order.order_status);
    });

    const pendingOrdersByDate = ordersData.filter(order => {
      const orderDate = new Date(order.createdAt);
      return orderDate.getMonth() === month && orderDate.getFullYear() === year && ['pending', 'Failure', 'N/A', 'Timeout'].includes(order.order_status);
    });

    const successData = Array.from({ length: 31 }, (_, i) => {
      const date = new Date(year, month, i + 1).toLocaleDateString();
      return successOrdersByDate.filter(order => new Date(order.createdAt).toLocaleDateString() === date).length;
    });

    const pendingData = Array.from({ length: 31 }, (_, i) => {
      const date = new Date(year, month, i + 1).toLocaleDateString();
      return pendingOrdersByDate.filter(order => new Date(order.createdAt).toLocaleDateString() === date).length;
    });

    return { successData, pendingData };
  };

  // Generate chart data for the default last month
  useEffect(() => {
    if (myorders.length > 0) {
      const currentDate = new Date();
      const lastMonth = (currentDate.getMonth() - 1 + 12) % 12; // Calculate last month
      const lastYear = lastMonth === 11 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();

      const { labels, data } = generateChartData(myorders, lastMonth, lastYear);
      setChartLabels(labels);
      setChartData(data);

      const { successData, pendingData } = generateOrderChartData(myorders, lastMonth, lastYear);
      setOrderChartData({ successData, pendingData });

      setSelectedMonth(lastMonth);
      setSelectedYear(lastYear);
    }
  }, [myorders]);

  // Handler function for month selection
  const handleMonthChange = (event) => {
    const selectedMonthIndex = parseInt(event.target.value);
    setSelectedMonth(selectedMonthIndex);

    if (myorders.length > 0) {
      const { labels, data } = generateChartData(myorders, selectedMonthIndex, selectedYear);
      setChartLabels(labels);
      setChartData(data);

      const { successData, pendingData } = generateOrderChartData(myorders, selectedMonthIndex, selectedYear);
      setOrderChartData({ successData, pendingData });
    }
  };

  // Handler function for year selection
  const handleYearChange = (event) => {
    const selectedYearValue = parseInt(event.target.value);
    setSelectedYear(selectedYearValue);

    if (myorders.length > 0) {
      const { labels, data } = generateChartData(myorders, selectedMonth, selectedYearValue);
      setChartLabels(labels);
      setChartData(data);

      const { successData, pendingData } = generateOrderChartData(myorders, selectedMonth, selectedYearValue);
      setOrderChartData({ successData, pendingData });
    }
  };

  const barChartData = {
    labels: chartLabels,
    datasets: [
      {
        label: 'Total Revenue',
        data: chartData,
        backgroundColor: '#4caf50',
      },
    ],
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: `Total Revenue for ${selectedMonth !== '' ? getMonthName(selectedMonth) : 'Last'} ${selectedYear !== '' ? selectedYear : ''}`,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Total Revenue',
        },
        ticks: {
          beginAtZero: true,
        },
      },
    },
  };

  const orderBarChartData = {
    labels: Array.from({ length: 31 }, (_, i) => i + 1), // Assuming max 31 days in a month
    datasets: [
      {
        label: 'Successful Orders',
        data: orderChartData.successData,
        backgroundColor: '#4caf50',
      },
      {
        label: 'Pending Orders',
        data: orderChartData.pendingData,
        backgroundColor: '#f44336', // Change the color to red
      },
    ],
  };

  const orderBarChartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Allow chart to resize properly
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Orders Overview',
      },
    },
  };

  return (
    <div className="admin-panel-container">
      <AdminNavbar />
      <div className="admin-stats">
        <div className="stat-item">
          <img className="admin-image" src="Images/Shop_Icon.png" alt="Products"/>
          <h3>Total Products</h3>
          <h2>{totalProducts}</h2>
        </div>
        <div className="stat-item">
          <img className="admin-image" src="Images/ShoppingCart.png" alt="Orders"/>
          <h3>Total Orders</h3>
          <h2>{totalOrders}</h2>
        </div>
        <div className="stat-item">
          <img className="admin-image" src="Images/rupee.png" alt="Revenue"/>
          <h3>Total Revenue</h3>
          <h2>{totalRevenue.toFixed(2)}</h2>
        </div>
        <div className="stat-item">
          <img className="admin-image" src="Images/pending.png" alt="Pending Orders"/>
          <h3>Pending Orders</h3>
          <h2>{pendingOrders}</h2>
        </div>
        <div className="stat-item">
          <img className="admin-image" src="Images/order.png" alt="Success Orders"/>
          <h3>Success Orders</h3>
          <h2>{successOrders}</h2>
        </div>
      </div>
      <div className='Chart-div'>
        <div className="chart-container">
          <Bar data={orderBarChartData} options={orderBarChartOptions} />
        </div>
        <div className="chart-container">
          <select value={selectedMonth} onChange={handleMonthChange}>
            <option value="">Select Month</option>
            <option value={0}>January</option>
            <option value={1}>February</option>
            <option value={2}>March</option>
            <option value={3}>April</option>
            <option value={4}>May</option>
            <option value={5}>June</option>
            <option value={6}>July</option>
            <option value={7}>August</option>
            <option value={8}>September</option>
            <option value={9}>October</option>
            <option value={10}>November</option>
            <option value={11}>December</option>
          </select>
          <select value={selectedYear} onChange={handleYearChange}>
            <option value="">Select Year</option>
            <option value={2023}>2023</option>
            <option value={2024}>2024</option>
            <option value={2025}>2025</option>
            {/* Add more years as needed */}
          </select>
          <Bar data={barChartData} options={barChartOptions} />
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
