// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.imageslider-home {
  background-color: transparent;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.slider-img {
  width: 100%;
  height:400px;
  display: block;
}

/* Media queries for responsive design */

@media (max-width: 768px) {
  .slider-mg {
    width: 333px; /* Adjust the width for smaller screens */
  }
  .imageslider {
  max-width: 333px;
    width: 300px;
  }
}`, "",{"version":3,"sources":["webpack://./src/FrontEnd/Slider/imageSlider/imageSlider.css"],"names":[],"mappings":";AACA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;;AAEA,wCAAwC;;AAExC;EACE;IACE,YAAY,EAAE,yCAAyC;EACzD;EACA;EACA,gBAAgB;IACd,YAAY;EACd;AACF","sourcesContent":["\n.imageslider-home {\n  background-color: transparent;\n  position: relative;\n  width: 100%;\n  height: auto;\n  overflow: hidden;\n}\n\n.slider-img {\n  width: 100%;\n  height:400px;\n  display: block;\n}\n\n/* Media queries for responsive design */\n\n@media (max-width: 768px) {\n  .slider-mg {\n    width: 333px; /* Adjust the width for smaller screens */\n  }\n  .imageslider {\n  max-width: 333px;\n    width: 300px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
