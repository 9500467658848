// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-section {
    background-color: #fff;
    padding: 50px 0;
}

.about-container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.heading {
    color: #333;
}

.mission-statement {
    color: #555;
    font-style: italic;
    margin-bottom: 20px;
}

.mission-description {
    color: #777;
    margin-bottom: 40px;
}

.feature-list {
    list-style: none;
    padding: 0;
}

.feature-item {
    color: #555;
    margin-bottom: 10px;
}

.contact-description {
    color: #777;
}

@media (max-width: 600px) {
    .feature-item {
        font-size: 14px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/AboutUs/AboutUs.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".about-section {\n    background-color: #fff;\n    padding: 50px 0;\n}\n\n.about-container {\n    max-width: 800px;\n    margin: 0 auto;\n    text-align: center;\n}\n\n.heading {\n    color: #333;\n}\n\n.mission-statement {\n    color: #555;\n    font-style: italic;\n    margin-bottom: 20px;\n}\n\n.mission-description {\n    color: #777;\n    margin-bottom: 40px;\n}\n\n.feature-list {\n    list-style: none;\n    padding: 0;\n}\n\n.feature-item {\n    color: #555;\n    margin-bottom: 10px;\n}\n\n.contact-description {\n    color: #777;\n}\n\n@media (max-width: 600px) {\n    .feature-item {\n        font-size: 14px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
