import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
    return (
        <div className="about-section">
            <div className="about-container">
                <h1 className="heading">Welcome to Dalal Technologies - WaveArt</h1>
                <p className="mission-statement">Empowering Your Academic Progress</p>
                <p className="mission-description">
                    At Dalal Technologies, operating under the banner of WaveArt, we are committed to revolutionizing your academic experience.
                    Specializing in providing solutions for old question papers, assignments, and university projects, we aim to simplify your educational journey.
                </p>

                <ul className="feature-list">
                    <h2>What Makes WaveArt Unique</h2>
                    <li className="feature-item">Quality Solutions: Meticulously crafted solutions for old question papers, assignments, and university projects, ensuring academic excellence.</li>
                    <li className="feature-item">User-Friendly Platform: Our website is designed with your convenience in mind. Easily navigate, explore, and make purchases hassle-free.</li>
                    <li className="feature-item">Customer-Centric Approach: Your satisfaction is our top priority. Our dedicated customer support team is here to address your queries and concerns promptly.</li>
                </ul>

                <ul className="feature-list">
                    <h2>Our Product Range</h2>
                    <li className="feature-item">Old Question Papers: Access a wide range of solved question papers to aid your preparation and understanding of exam patterns.</li>
                    <li className="feature-item">Assignments: Save time and elevate your performance with expertly crafted assignment solutions tailored to meet your academic requirements.</li>
                    <li className="feature-item">University Projects: Find unique and well-researched projects that align with university standards and expectations.</li>
                </ul>

                <div>
                    <h2>Contact Us</h2>
                    <p className="contact-description">Have a question, feedback, or need assistance? Don't hesitate to reach out. Contact our friendly customer support team at +919654400726/support@dalaltechnologies.com.</p>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
