// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-body {
  max-width: 1250px; /* Set a maximum width for the container */
  margin: 0 auto; /* Center the container */
  padding: 20px;
  /* Media query for mobile devices */
  @media only screen and (max-width: 600px) {
    padding: 10px; /* Adjust padding for smaller screens */
    box-shadow: none; /* Remove box shadow on mobile */
  }
}

.product-input-body {
  display: flex;
  justify-content:space-around;
  align-items: center;

  /* Add animation to the product-input-body */
  animation: fadeInUp 0.5s ease-in-out;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.dropdown-list-container {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

/* Rest of your existing styles */


`, "",{"version":3,"sources":["webpack://./src/FrontEnd/Product/Product.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB,EAAE,0CAA0C;EAC7D,cAAc,EAAE,yBAAyB;EACzC,aAAa;EACb,mCAAmC;EACnC;IACE,aAAa,EAAE,uCAAuC;IACtD,gBAAgB,EAAE,gCAAgC;EACpD;AACF;;AAEA;EACE,aAAa;EACb,4BAA4B;EAC5B,mBAAmB;;EAEnB,4CAA4C;EAC5C,oCAAoC;;EAEpC;IACE;MACE,UAAU;MACV,4BAA4B;IAC9B;IACA;MACE,UAAU;MACV,wBAAwB;IAC1B;EACF;AACF;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA,iCAAiC","sourcesContent":[".card-body {\n  max-width: 1250px; /* Set a maximum width for the container */\n  margin: 0 auto; /* Center the container */\n  padding: 20px;\n  /* Media query for mobile devices */\n  @media only screen and (max-width: 600px) {\n    padding: 10px; /* Adjust padding for smaller screens */\n    box-shadow: none; /* Remove box shadow on mobile */\n  }\n}\n\n.product-input-body {\n  display: flex;\n  justify-content:space-around;\n  align-items: center;\n\n  /* Add animation to the product-input-body */\n  animation: fadeInUp 0.5s ease-in-out;\n\n  @keyframes fadeInUp {\n    from {\n      opacity: 0;\n      transform: translateY(-20px);\n    }\n    to {\n      opacity: 1;\n      transform: translateY(0);\n    }\n  }\n}\n\n.dropdown-list-container {\n  width: 100%;\n  padding: 10px;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n  font-size: 14px;\n}\n\n/* Rest of your existing styles */\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
