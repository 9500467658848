import React, { useState, useEffect, useRef } from 'react';
import './CartLayOut.css';
import { MdDelete } from 'react-icons/md';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link } from 'react-router-dom';

const CartLayOut = ({ data, onRemove, countCartItems, handlePayNow }) => {
  const [showMessage, setShowMessage] = useState(false);
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const totalAmount = data.reduce((total, { Price, qty }) => total + Price * qty, 0);
  const canvasRef = useRef(null);
  const isMobile = window.innerWidth <= 768; // Set your desired breakpoint

  useEffect(() => {
    if (canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      context.willReadFrequently = true;
    }
  }, []);

  const handleClickRemove = (item) => {
    onRemove(item);
  };

  const handleClickPayNow = () => {
    if (isPolicyAccepted) {
      if (!name || !email || !phone) {
        alert('Please fill in all fields.');
        return;
      }

      if (!validateEmail(email)) {
        alert('Please enter a valid email address.');
        return;
      }

      if (!validatePhone(phone)) {
        alert('Please enter a valid phone number.');
        return;
      }

      try {
        const productIds = data.map((item) => item._id).toString();
        const title = data.map((item) => item.Title).toString();
        const totalAmount = data.reduce((total, { Price, qty }) => total + Price * qty, 0);

        handlePayNow(totalAmount, productIds, title, name, email, phone);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert('Please accept the Term and Conditions policy before proceeding to buy.');
    }
  };


  const handleTogglePolicy = () => {
    setIsPolicyAccepted(!isPolicyAccepted);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const validateEmail = (email) => {
    // Implement your email validation logic here
    // For example, a simple check for presence of '@' and '.'
    return email.includes('@') && email.includes('.');
  };

  const validatePhone = (phone) => {
    // Implement your phone number validation logic here
    // You can use regular expressions or other methods
    // For example, a simple check for length and numerical values
    return /^(?:\+91|0)?[0-9]{10}$/.test(phone);
    return /^(?:\+91|0)?[0-9]{10}$/.test(phone);
  };

  return (
    <>
    <div className={`layout-div ${isMobile ? 'mobile-layout' : ''}`}>
        <div className='heading'>Your Cart</div>
        <div className="input-field-cart_layout">
          <input
            type="name"
            placeholder='Your Name'
            className='input-cart_layout'
            value={name}
            onChange={handleNameChange}
          />
          <input
            type="email"
            placeholder='Email Id '
            className='input-cart_layout'
            value={email}
            onChange={handleEmailChange}
          />

          <input
            type="tel"
            placeholder='Mobile No '
            className='input-cart_layout'
            value={phone}
            onChange={handlePhoneChange}
          />
        </div>
        <div className='main-div'>
          <div className='left-div'>
            <Scrollbars>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th><center>Image</center></th>
                      <th><center>Title</center></th>
                      <th><center>Price</center></th>
                      <th><center>Quantity</center></th>
                      <th><center>Total Price</center></th>
                      <th><center>Remove</center></th>
                    </tr>

                  </thead>
                  <tbody>
                    {data.map((item) => (
                      <tr key={item.id}>
                        <td><center><img className='left-div-image' src={`https://waveart.in/uploads/${item.Image}`} alt='img' /></center></td>
                        <td className='title-div'><center>{item.Title}</center></td>
                        <td className='amount-div'><center>₹ <span>{item.Price}/-</span></center></td>
                        <td className='amount-div'> <center><span>{item.qty}</span></center></td>
                        <td className='amount-div'><center>₹ <span>{item.Price * item.qty}</span></center></td>
                        <td className='remove-div' onClick={() => handleClickRemove(item)}>
                        <center>  <MdDelete /></center> 
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Scrollbars>
          </div>
          <div className='right-div'>
            <h1>
              Subtotal <span>{countCartItems} items</span>
            </h1>

            <p>
            <center> {!isMobile && <img src="/Images/rupee.png" className='rupee-image' alt="Rupee Image" />}</center> 
            <span><h2><center> {totalAmount}/-</center> </h2></span>
          </p>

            <button
              className="button"
              onClick={handleClickPayNow}>
              Buy Now
            </button>
            <br></br> <br></br> <br></br>
            Pay to DalalTechnologies
            {showMessage && <div>Added to Cart</div>}
          </div>
        </div>
        <canvas ref={canvasRef} style={{ display: 'none' }} />
        <div className="cart-checkbox-div">
          <input
            type="checkbox"
            checked={isPolicyAccepted}
            onChange={handleTogglePolicy}
          />
          <p>I accept the Term and Conditions</p>
          <Link to="/CancellationPolicy" className="cart-cancellation-policy-link">
            Term and Conditions
          </Link>
        </div>
      
      </div>
    </>
  )
};

export default CartLayOut;