import React, { useState } from 'react';
import './Card.css';

function Card({ onAdd, data, filterProduct }) {
  const [addedToCart, setAddedToCart] = useState(null);

  const handleAddToCart = (item) => {
    if (addedToCart) return; // Prevent multiple clicks while a message is displayed

    onAdd(item);
    setAddedToCart(item._id);
    setTimeout(() => setAddedToCart(null), 2000); // Clear message after 2 seconds
  };

  const itemsToDisplay = filterProduct.length ? filterProduct : data;

  return (
    <div className='card-comp'>
      {itemsToDisplay.map((item) => (
        <div className='card' key={item._id}>
           {/* <img
          className='srcimg'
          src={`https://waveart.in/uploads/${item.Image}`}
          alt='img'
        /> */}
        <img className='srcimg' src='Images/wave_logo.png'/>
          <div className='card-content'>
            <h3>{item.Title}</h3>
            <h2>{item.Category}</h2>
            {item.subcategory && <h5>{item.subcategory}</h5>}
            <h2 className='product-price'>₹ {item.Price}/-</h2>
            <button
              onClick={() => handleAddToCart(item)}
              className={`button-card ${addedToCart === item._id ? 'disabled' : ''}`}
              disabled={addedToCart === item._id} // Disable button if message is shown
            >
              {addedToCart === item._id ? 'Added to cart!' : 'Add To Cart'}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Card;
