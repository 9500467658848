import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Blog.css';

const API_BASE_URL = "https://waveart.in";

// Function to strip HTML tags from content
const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};

// Function to create a URL-friendly slug from a text
const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Remove leading -
    .replace(/-+$/, '');            // Remove trailing -
};

const Blog = () => {
  const [blogsData, setBlogsData] = useState([]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/BlogData`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => setBlogsData(data))
      .catch(error => {
        console.error('Error fetching blogs:', error);
        // Optionally, you can set some state to show an error message in the UI
      });
  }, []);
  const handleBlogClick = (blogId) => {
    // Save the blogId into local storage
    localStorage.setItem('currentBlogId', blogId);
  };

  return (
    <div className="blog-list">
      <center>
        <h1>Blogs</h1>
      </center>
      <div className="blog-grid">
        {blogsData.map((blog) => (
          <Link
            key={blog._id}
            // Use only slugified title in the route
            to={`/details/${slugify(blog.Title)}`} 
            className="blog-item"
            onClick={() => handleBlogClick(blog._id)}
          >
            <div className="blog-content">
              <div className="blog-header">
                <h3 className="blog-title">{blog.Title}</h3>
              </div>
              <div className="blog-details">
                <img
                  className="blog-image"
                  src={`${API_BASE_URL}/uploads/${blog.Image}`} // Corrected template literal
                  alt="Blog"
                />
                {/* <p>{stripHtmlTags(blog.Content).slice(0, 100)}...</p> */}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Blog;
