// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.empty-cart-image{
    margin-top: 3%;
    margin-left:35% ;
    height: 30%;
    width: 30%;
    }
     
    .Empty-btn{
        background-color: rgb(245, 244, 237);
        margin-left:40%;
        color: rgb(255, 33, 33);
        text-decoration: none;
        border-radius: 10px;
        height: 50px;
        width: 20%;
        font-size: larger;
        font-weight: bold;
        margin-bottom: 3%;
    
    }
    .Empty-btn:hover{
     
        color: rgb(21, 248, 74);
        
    }
    
    `, "",{"version":3,"sources":["webpack://./src/FrontEnd/Cart/index.css"],"names":[],"mappings":";AACA;IACI,cAAc;IACd,gBAAgB;IAChB,WAAW;IACX,UAAU;IACV;;IAEA;QACI,oCAAoC;QACpC,eAAe;QACf,uBAAuB;QACvB,qBAAqB;QACrB,mBAAmB;QACnB,YAAY;QACZ,UAAU;QACV,iBAAiB;QACjB,iBAAiB;QACjB,iBAAiB;;IAErB;IACA;;QAEI,uBAAuB;;IAE3B","sourcesContent":["\n.empty-cart-image{\n    margin-top: 3%;\n    margin-left:35% ;\n    height: 30%;\n    width: 30%;\n    }\n     \n    .Empty-btn{\n        background-color: rgb(245, 244, 237);\n        margin-left:40%;\n        color: rgb(255, 33, 33);\n        text-decoration: none;\n        border-radius: 10px;\n        height: 50px;\n        width: 20%;\n        font-size: larger;\n        font-weight: bold;\n        margin-bottom: 3%;\n    \n    }\n    .Empty-btn:hover{\n     \n        color: rgb(21, 248, 74);\n        \n    }\n    \n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
