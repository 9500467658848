import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './DetailsBlogs.css';

const DetailsBlogs = () => {
  const { blogId: urlBlogId } = useParams(); // Extracting blogId from the URL
  const [blogId, setBlogId] = useState(null);
  const [blogDetails, setBlogDetails] = useState(null);

  useEffect(() => {
    const localBlogId = localStorage.getItem('currentBlogId'); // Retrieve blogId from local storage
    const finalBlogId = localBlogId || urlBlogId; // Use local storage value if available, otherwise use URL value

    setBlogId(finalBlogId); // Set the blogId

    const fetchBlogDetails = async () => {
      try {
        const response = await axios.get(`https://waveart.in/BlogData/${finalBlogId}`);
        setBlogDetails(response.data);
      } catch (error) {
        console.error('Error fetching blog details:', error);
      }
    };

    if (finalBlogId) {
      fetchBlogDetails();
    }
  }, [urlBlogId]); // Dependency added to re-run effect when urlBlogId changes

  if (!blogDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="details-blogs-container">
      <h2>{blogDetails.Title}</h2>
      <img
        className="blog-image"
        src={`https://waveart.in/uploads/${blogDetails.Image}`}
        alt="img"
      />
      {/* Render content as HTML */}
      <div dangerouslySetInnerHTML={{ __html: blogDetails.Content }} />
    </div>
  );
};

export default DetailsBlogs;
