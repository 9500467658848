import React, { useState, useEffect } from "react";
import axios from "axios";
import "./BlogUpload.css";
import AdminNavbar from "../adminNavbar";
import JoditEditor from "jodit-react";

const apiBaseUrl = "https://waveart.in/";

const BlogUpload = ({ blogsData, setBlogs }) => {
  const [Title, setTitle] = useState("");
  const [Content, setContent] = useState("");
  const [Image, setImage] = useState(null);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [expandedBlogs, setExpandedBlogs] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    fetchData(); // Fetch data when component mounts
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}BlogData`);
      const sortedBlogs = response.data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      setBlogs(sortedBlogs);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Basic validation for file type and size
    if (file) {
      if (!file.type.startsWith('image/')) {
        setErrorMessage("Please select a valid image file.");
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        setErrorMessage("File size should not exceed 5MB.");
        return;
      }
      setImage(file);
    }
  };

  const handleBlogSubmit = async (event) => {
    event.preventDefault();
    
    if (!Title || !Content) {
      setErrorMessage("Please fill out all required fields.");
      return;
    }
    
    try {
      if (selectedBlog) {
        // Prepare the updated data for the selected blog
        const formData = new FormData();
        if (Image) formData.append("Image", Image); // Include new image if provided
        formData.append("Title", Title);
        formData.append("Content", Content);
        formData.append("ImageTitle", selectedBlog.ImageTitle || "");
        formData.append("ImageDescription", selectedBlog.ImageDescription || "");
    
        // Send updated data as FormData
        await axios.put(
          `${apiBaseUrl}BlogData/${selectedBlog._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setSuccessMessage("Blog updated successfully.");
        fetchData(); // Refresh the blog list to show the latest
      } else {
        // Create a new blog
        const formData = new FormData();
        if (Image) formData.append("Image", Image); // Include Image only if a new one is provided
        formData.append("Title", Title);
        formData.append("Content", Content);
        const response = await axios.post(
          `${apiBaseUrl}BlogData`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const newBlog = response.data;
        setBlogs((prevBlogs) => [newBlog, ...prevBlogs].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
        setSuccessMessage("Blog added successfully.");
      }
    
      resetForm();
    } catch (error) {
      console.error("Error submitting blog:", error);
      setErrorMessage("Failed to submit blog. Please try again.");
    }
  };
  
  const handleBlogEdit = (blog) => {
    setSelectedBlog(blog);
    setTitle(blog.Title);
    setContent(blog.Content);
    setImage(null); // Clear the image state so it won't appear selected
    setErrorMessage(""); // Clear error and success messages
    setSuccessMessage("");
  };

  const handleBlogDelete = async (blog) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this blog?");
    if (!confirmDelete) return;

    try {
      await axios.delete(`${apiBaseUrl}BlogData/${blog._id}`);
      fetchData(); // Refresh the blog list to reflect the deletion
      resetForm();
      setSuccessMessage("Blog deleted successfully.");
    } catch (error) {
      console.error("Error deleting blog:", error);
      setErrorMessage("Failed to delete blog. Please try again.");
    }
  };

  const handleExpandBlog = (blogId) => {
    setExpandedBlogs((prevExpandedBlogs) => [...prevExpandedBlogs, blogId]);
  };

  const handleCollapseBlog = (blogId) => {
    setExpandedBlogs((prevExpandedBlogs) =>
      prevExpandedBlogs.filter((id) => id !== blogId)
    );
  };

  const resetForm = () => {
    setSelectedBlog(null);
    setTitle("");
    setContent("");
    setImage(null); // Clear the image state
    document.querySelector('input[type="file"]').value = ""; // Clear the file input field
    setErrorMessage("");
    setSuccessMessage("");
  };

  return (
    <div>
      <AdminNavbar />
      <div className="blog-upload">
        <h2>Blog Upload</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        <form onSubmit={handleBlogSubmit}>
          <input
            type="text"
            placeholder="Title"
            value={Title}
            onChange={(e) => setTitle(e.target.value)}
            className="blog-input"
            required
          />
          <JoditEditor value={Content} onChange={(value) => setContent(value)} />
          <input
            type="file"
            onChange={handleFileChange}
            className="file-input"
          />
          <button type="submit" className="button">
            {selectedBlog ? "Update Blog" : "Add Blog"}
          </button>
          <button type="button" onClick={resetForm} className="button">
            Cancel
          </button>
        </form>
        <div className="blog-list">
          <h2>Existing Blogs</h2>
          <div className="blog-items">
            {blogsData.map((blog) => (
              <div className="blog-item" key={blog._id}>
                <img
                  className="srcimg1"
                  src={`${apiBaseUrl}uploads/${blog.Image}`}
                  alt="Blog"
                />
                <div className="blog-content">
                  <h3>{blog.Title}</h3>
                  {expandedBlogs.includes(blog._id) ? (
                    <div>
                      <div dangerouslySetInnerHTML={{ __html: blog.Content }} />
                      <button
                        className="button"
                        onClick={() => handleCollapseBlog(blog._id)}
                      >
                        Show Less
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div dangerouslySetInnerHTML={{ __html: blog.Content.slice(0, 100) }} />
                      <button
                        className="button"
                        onClick={() => handleExpandBlog(blog._id)}
                      >
                        Show More
                      </button>
                    </div>
                  )}
                </div>
                <div className="blog-actions">
                  <button
                    className="button"
                    onClick={() => handleBlogEdit(blog)}
                  >
                    Edit
                  </button>
                  <button
                    className="button"
                    onClick={() => handleBlogDelete(blog)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogUpload;
