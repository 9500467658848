import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./SliderImage.css";
import AdminNavbar from '../adminNavbar';
import Scrollbars from 'react-custom-scrollbars-2';

const SliderImage = ({ images }) => {

  const [uploadSuccess, setUploadSuccess] = useState('');
  const [deleteSuccess, setDeleteSuccess] = useState('');
  const [newImage, setNewImage] = useState({
    Title: '',
    Description: '',
    Image: null,
  });


  useEffect(() => {
    // Clear success messages after 3 seconds
    const uploadTimeout = setTimeout(() => {
      setUploadSuccess('');
    }, 3000);

    const deleteTimeout = setTimeout(() => {
      setDeleteSuccess('');
    }, 3000);

    return () => {
      clearTimeout(uploadTimeout);
      clearTimeout(deleteTimeout);
    };
  }, [uploadSuccess, deleteSuccess]);

  const handleImageUpload = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('Title', newImage.Title);
    formData.append('Description', newImage.Description);
    formData.append('Image', newImage.Image);

    try {
      const response = await axios.post('https://waveart.in/image', formData);

      setUploadSuccess('Image uploaded successfully');
      console.log('Image uploaded successfully:', response.data.newImg);

      // Update the list of images
      setNewImage({
        Title: '',
        Description: '',
        Image: null,
      });
    } catch (error) {
      console.error('Error uploading image:', error);
      setUploadSuccess('Error uploading image');
    }
  };

  const handleImageDelete = async (imageId) => {
    try {
      const response = await axios.delete(`https://waveart.in/image/${imageId}`);

      setDeleteSuccess('Image deleted successfully');
      console.log('Image deleted successfully:', response.data.deletedImage);
    } catch (error) {
      console.error('Error deleting image:', error);
      setDeleteSuccess('Error deleting image');
    }
  };

  return (
    <>
      <AdminNavbar />
      <div className="imageslider">
        <div className='upload-image-from'>
        <form onSubmit={handleImageUpload} className='imageslider-form'>
  <h2>Add New Image</h2>
  <p>{uploadSuccess}</p>
  <input
    placeholder="Title"
    className='imageslider-input'
    type="text"
    id="Title"
    name="Title"
    value={newImage.Title}
    onChange={(e) => setNewImage({ ...newImage, Title: e.target.value })}
  />
  <textarea
    placeholder='Description'
    className='imageslider-textarea'
    id="Description"
    name="Description"
    value={newImage.Description}
    onChange={(e) => setNewImage({ ...newImage, Description: e.target.value })}
  />
  <input className='imageslider-file-input-label'
    type="file"
    id="Image"
    name="Image"
    onChange={(e) => setNewImage({ ...newImage, Image: e.target.files[0] })}
  />
  <button className='imageslider-button' type="submit">Upload Image</button>
</form>

        </div>

        <div className='show-image-div'>

          {images.map((img) => (
            <div key={img._id} className='imageslider-image'>
              <img className='slideimage-upload' src={`https://waveart.in/uploads/${img.ImgUrl}`} alt={img.Title} />
              <button className='Delete-button ' onClick={() => handleImageDelete(img._id)}>Delete</button>
            </div>
          ))}

        </div>

        <p>{deleteSuccess}</p>
      </div>
    </>
  );
};

export default SliderImage;
