

import React, { useState } from 'react';
import ProductList from '../ProductList';
import UploadProduct from '../UploadProductData';
import './UploadManagement.css';
import AdminNavbar from '../../adminNavbar';

const UploadManagement = ({ productData, categories, updateProductData }) => {
  const [editProduct, setEditProduct] = useState(null);

  return (
    <>
      <AdminNavbar />
      <div className='upload-management-container'>
        <div className='update-list'>
          <UploadProduct
            categories={categories}
            updateProductData={updateProductData}
            editProduct={editProduct} // Pass editProduct data
          />
        </div>
        <div>
          <ProductList
            productData={productData}
            setEditProduct={setEditProduct} // Pass setEditProduct function
          />
        </div>
      </div>
    </>
  );
};

export default UploadManagement;
