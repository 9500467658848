import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProductList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const ProductList = ({ productData, setEditProduct }) => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [message, setMessage] = useState(null);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [showConfirmEditDialog, setShowConfirmEditDialog] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [productToDelete, setProductToDelete] = useState(null);

  useEffect(() => {
    if (Array.isArray(productData)) {
      const combinedProducts = [...productData, ...products];
      const sortedProducts = combinedProducts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setProducts(sortedProducts);
    }
  }, [productData]);

  const handleDeleteClick = (productId) => {
    setProductToDelete(productId);
    setShowConfirmDeleteDialog(true);
  };

  const handleEditClick = (product) => {
    setProductToEdit(product);
    setShowConfirmEditDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (!productToDelete) return;

    try {
      const response = await axios.delete(`https://waveart.in/product/${productToDelete}`);
      if (response.status === 200) {
        setProducts((prevProducts) => prevProducts.filter((product) => product._id !== productToDelete));
        setMessage('Product deleted successfully');
      } else {
        setMessage('Failed to delete product');
      }
    } catch (error) {
      setMessage('Error occurred while deleting product');
      console.error('Error occurred while deleting product:', error);
    }
    
    setShowConfirmDeleteDialog(false);
    setProductToDelete(null);
    setTimeout(() => setMessage(null), 5000);
  };

  const handleCancelDelete = () => {
    setShowConfirmDeleteDialog(false);
    setProductToDelete(null);
  };

  const handleConfirmEdit = () => {
    if (productToEdit) {
      setEditProduct(productToEdit);
      setProductToEdit(null);
    }
    setShowConfirmEditDialog(false);
  };

  const handleCancelEdit = () => {
    setShowConfirmEditDialog(false);
    setProductToEdit(null);
  };

  const handleEdit = (product) => {
    setEditProduct(product);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = searchTerm
    ? products.filter((product) => product?.Title?.toLowerCase().includes(searchTerm.toLowerCase()))
    : products;

  return (
    <>
    <div className="product-list-container">
      <div className="search-bar">
        <input
          className='search-bar-input'
          type="text"
          placeholder="Search by title"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      <div className="product-table-container">
        <table className="product-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Category</th>
              <th>Price</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product) => (
              <tr key={product._id} className="fade-in">
                <td>{product.Title}</td>
                <td>{product.Category}</td>
                <td>₹{product.Price}-/</td>
                <td>
                  <button className="button-product-list" onClick={() => handleEditClick(product)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button className="button-product-list" onClick={() => handleDeleteClick(product._id)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {message && <div className="message">{message}</div>}
      {showConfirmDeleteDialog && (
        <div className="confirm-dialog-overlay">
          <div className="confirm-dialog">
            <p>Do you want to delete this product?</p>
            <button className="confirm-button" onClick={handleConfirmDelete}>OK</button>
            <button className="cancel-button" onClick={handleCancelDelete}>Cancel</button>
          </div>
        </div>
      )}
      {showConfirmEditDialog && (
        <div className="confirm-dialog-overlay">
          <div className="confirm-dialog">
            <p>Do you want to edit this product?</p>
            <button className="confirm-button" onClick={handleConfirmEdit}>OK</button>
            <button className="cancel-button" onClick={handleCancelEdit}>Cancel</button>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default ProductList;
