// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details-blogs-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-in-out;
  
    @media only screen and (max-width: 600px) {
      padding: 10px;
    }
  }
  
  .blog-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/FrontEnd/Blogs/DetailsBlogs.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,uCAAuC;IACvC,kCAAkC;;IAElC;MACE,aAAa;IACf;EACF;;EAEA;IACE,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE;MACE,UAAU;MACV,4BAA4B;IAC9B;IACA;MACE,UAAU;MACV,wBAAwB;IAC1B;EACF","sourcesContent":[".details-blogs-container {\n    max-width: 800px;\n    margin: 20px auto;\n    padding: 20px;\n    background-color: #fff;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    animation: fadeIn 0.5s ease-in-out;\n  \n    @media only screen and (max-width: 600px) {\n      padding: 10px;\n    }\n  }\n  \n  .blog-image {\n    width: 100%;\n    max-height: 400px;\n    object-fit: cover;\n    margin-bottom: 20px;\n  }\n  \n  @keyframes fadeIn {\n    from {\n      opacity: 0;\n      transform: translateY(-20px);\n    }\n    to {\n      opacity: 1;\n      transform: translateY(0);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
