import React, { useState, useEffect, useRef } from 'react';
import "./UploadProductData.css"; // Ensure this import is correct

const UploadProduct = ({ categories, updateProductData, editProduct }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [submissionMessage, setSubmissionMessage] = useState("");

  const imageInputRef = useRef(null);
  const pdfInputRef = useRef(null);

  useEffect(() => {
    if (editProduct) {
      setTitle(editProduct.Title || "");
      setDescription(editProduct.Description || "");
      setCategory(editProduct.Category || "");
      setPrice(editProduct.Price || "");
    }
  }, [editProduct]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !description || !category || !price) {
      setSubmissionMessage('Please fill in all fields.');
      setTimeout(() => setSubmissionMessage(null), 5000);
      return;
    }

    const formData = new FormData();
    formData.append('Title', title);
    formData.append('Description', description);
    formData.append('Category', category);
    formData.append('Price', price);
    if (image) formData.append('image', image);
    if (pdf) formData.append('pdf', pdf);

    const url = editProduct ? `https://waveart.in/product/${editProduct._id}` : 'https://waveart.in/product';

    try {
      const response = await fetch(url, {
        method: editProduct ? 'PUT' : 'POST',
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        console.log('Product saved successfully:', data.updatedProduct || data.newProduct);
        updateProductData(data.updatedProduct || data.newProduct);
        setSubmissionMessage('Product saved successfully.');
        resetForm(); // Reset form fields after successful submission
      } else {
        console.error('Error saving product:', data.message);
        setSubmissionMessage(data.message);
      }
    } catch (error) {
      console.error('Error:', error.message);
      setSubmissionMessage('An error occurred while processing your request.');
    }

    setTimeout(() => setSubmissionMessage(null), 5000);
  };

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setCategory("");
    setPrice("");
    setImage(null);
    setPdf(null);

    // Reset file inputs using refs
    if (imageInputRef.current) imageInputRef.current.value = "";
    if (pdfInputRef.current) pdfInputRef.current.value = "";
  };

  return (
    <div className="product-upload-container">
      <center><h1>{editProduct ? 'Edit Product' : 'Upload Product'}</h1></center>
      <form onSubmit={handleSubmit}>
        <div className='upload-form'>
          <div className='form-div'>
            <input
              type="text"
              name='Title'
              placeholder="Title"
              className="form-input-title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <input
              type="number"
              name='Price'
              placeholder="Price"
              className="form-input-price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            <textarea
              name='Description'
              className="form-textarea-description"
              placeholder='Description'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
            <select
              value={category}
              className="form-select-category"
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Select Category</option>
              {categories.map((item) => (
                <option key={item._id} value={item.category}>
                  {item.category}
                </option>
              ))}
            </select>
          </div>
          <div className='upload-section'>
            <h5>Upload Product Image</h5>
            <input
              className='form-file-input-image'
              type="file"
              name="image"
              accept="image/*"
              onChange={(e) => setImage(e.target.files[0])}
              ref={imageInputRef}
            />
            <h5>Upload Product PDF</h5>
            <input
              className='form-file-input-pdf'
              type="file"
              name="pdf"
              accept="application/pdf"
              onChange={(e) => setPdf(e.target.files[0])}
              ref={pdfInputRef}
            />
            <div>
              <input className='submit-button' type="submit" value="Submit" />
              {submissionMessage && <p>{submissionMessage}</p>}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UploadProduct;
