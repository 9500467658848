import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './Downloadpdf.css';
import axios from 'axios';

const DownloadPage = ({ productData }) => {
  const userId = localStorage.getItem('userId');
  const [successOrders, setSuccessOrders] = useState([]);
  const [matchedProducts, setMatchedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_URL = "https://waveart.in";

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${API_URL}/pay/getOrder`);
        const orders = response.data.orderData;

        if (!Array.isArray(orders)) {
          setIsLoading(false);
          return;
        }

        const filteredOrders = orders.filter(order =>
          order.userId === userId && order.order_status === 'success'
        );

        setSuccessOrders(filteredOrders || []);

        if (filteredOrders.length > 0 && productData && productData.length > 0) {
          const matchedProductsArray = filteredOrders.flatMap(order => {
            const orderProductIds = order.productID.split(',').map(id => id.trim());
            return orderProductIds
              .map(orderProductId => productData.find(product => product._id === orderProductId))
              .filter(match => match);
          });
          setMatchedProducts(matchedProductsArray);
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setError('Failed to fetch orders. Please try again.');
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [userId, productData]);

  const handleDownload = async (pdfUrl, title) => {
    try {
      const response = await fetch(pdfUrl);
      if (!response.ok) throw new Error('Network response was not ok.');

      const pdfBlob = await response.blob();
      const fileName = `Waveart.in_${title}.pdf`;
      saveAs(pdfBlob, fileName);
    } catch (error) {
      console.error('Error downloading PDF:', error);
      setError('Error downloading PDF. Please try again.');
    }
  };

  return (
    <div>
      <div className="user-info">
        {isLoading ? (
          <>
            <Skeleton circle={true} height={100} width={100} />
            <h1><Skeleton /></h1>
            <p><Skeleton count={5} /></p>
          </>
        ) : successOrders.length ? (
          successOrders.map(order => (
            <div key={order._id}>
              <img className='download-img' src='Images/Downloaduser.png' alt='User profile' />
              <h1>Welcome, {order.name}!</h1>
              <p><strong>Email:</strong> {order.email}</p>
              <p><strong>Phone:</strong> {order.phone}</p>
              <p><strong>Order Status:</strong> {order.order_status}</p>
              <p><strong>Order ID:</strong> {order.order_id}</p>
              <p><strong>Payment Id:</strong> {order.order_id}</p>
              <h3 className='red-text'>Capture a screenshot of this page to ensure accurate payment and order details for future reference</h3>
            </div>
          ))
        ) : (
          <p>No successful orders found.</p>
        )}
      </div>

      <div className="download-table">
        {error && <div className="error-message">{error}</div>}

        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Download PDF</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td><Skeleton className="skeleton" /></td>
                <td><Skeleton className="skeleton" /></td>
              </tr>
            ) : (
              matchedProducts.map(product => (
                <tr key={product._id}>
                  <td>{product.Title}</td>
                  <button className='button' onClick={() => handleDownload(`${API_URL}/uploads/${product.Pdf}`, product.Title)}>
                      <i className="fas fa-download"></i>
                  </button>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DownloadPage;
